@tailwind base;
@tailwind components;
@tailwind utilities;

body {
   margin: 0;
   font-family: "Inter", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

@font-face {
   font-family: "Peignot";
   src: url("./assets/fonts/PeignotLTStd-Demi.otf");
}

@font-face {
   font-family: "Inter";
   src: url("./assets/fonts/Inter-Medium.ttf") format("truetype");
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: "Inter-Bold";
   src: url("./assets/fonts/Inter-Bold.ttf") format("truetype");
   font-weight: bold;
   font-style: normal;
}

h1 {
   font-size: 2rem;
   font-weight: 700;
   font-family: "Inter-Bold";
}

h2 {
   font-size: 1.5rem;
   font-weight: 700;
   font-family: "Inter-Bold";
}

h3 {
   font-size: 1.25rem;
   font-weight: 700;
   font-family: "Inter-Bold";
}

h4 {
   font-size: 1rem;
   font-weight: 700;
   font-family: "Inter-Bold";
}

h5 {
   font-size: 0.875rem;
   font-weight: 700;
   font-family: "Inter-Bold";
}

.scroll-bg-hidden::-webkit-scrollbar {
   background-color: transparent;
}
