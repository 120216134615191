.react-calendar {
    border-radius: 10px;
}

.react-calendar__tile:disabled {
    background-color: rgb(237, 120, 120);
    border-radius: 10px;
}

.react-calendar__month-view__days__day {
    color: black
}
.react-calendar__month-view__days__day--weekend {
    color: rgb(45, 80, 208)
}

.react-calendar__tile--active {
    background-color: rgb(89, 204, 89);
    border-radius: 10px;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background-color: rgb(89, 204, 89);
    border-radius: 10px;
}

.highlight-green {
    background-color: rgb(40, 156, 17) !important;
    color: white !important;
    border-radius: 10px;
  }